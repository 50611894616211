import React, { useEffect } from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Technologies from "./Technologies";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function AWS() {
  const P1 = require("../../assets/pages/1.png");

  return (
    <div>
      {/* Breadcrumbs Start */}
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img4 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Amazon Web Services (AWS)" />

              <span className="sub-text">
                Whether you're already familiar with Amazon Web Services or
                still seeking answers, you've come to the right place. Our team
                has extensive experience with AWS and can provide guidance and
                the latest knowledge on the most advanced AWS services.{" "}
              </span>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}
      {/* About Section Start */}
      <Section>
        <div className="rs-about py-0">
          <div className="container custom">
            <div className="row d-flex align-items-center">
              <div className="col-lg-4 md-mb-50">
                <div className="images-part">
                  <img src={P1} alt="Images" className="w-100" />
                </div>
              </div>
              <div className="col-lg-8 pl-60 md-pl-15 mt-4 mt-sm-0">
                <div className="sec-title">
                  <h1>AWS</h1>
                  <p>
                    Savrinda understands that data retention and buying space on
                    the cloud can be expensive for businesses. However, we also
                    recognize the importance of having adequate cloud space to
                    meet business needs.
                  </p>
                  <p>
                    we offer our expertise and familiarity with AWS to assist
                    you in finding cost-effective solutions. Our aim is to help
                    you make the most of AWS while providing guidance and
                    support every step of the way.
                  </p>
                  <p>
                    we offer our expertise and familiarity with AWS to assist
                    you in finding cost-effective solutions. Our aim is to help
                    you make the most of AWS while providing guidance and
                    support every step of the way.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* About Section End */}

      <Section className="bg-gray">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <h2 className="title pb-22">
                    Choose Savrinda for superior AWS assistance. Get in touch
                    with us today to alleviate your concerns!
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* Services Section End */}
      <Section>
        <div className="pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Here’s how we can help" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    {/* <div class="services-icon">
                                <img src="assets/images/services/style3/4.png" alt="Services">
                            </div> */}
                    <div className="services-content">
                      <h5 className="services-title">
                        Guide
                        <br />
                        on Migration
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Savrinda is keeping pace with the latest advancements in
                        technology and transitioning from traditional practices
                        to modern ones. We assist you in migrating from Hadoop
                        to AWS by providing a comprehensive and analytical
                        migration guide.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        A Trusted Data <br /> Platform
                      </h5>
                      <p className="services-txt">
                        {" "}
                        With Savrinda, you can enhance your online data platform
                        by utilizing our complimentary cloud automation tool
                        that enables you to build infrastructure and eliminate
                        errors in real-time.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Data Pipelines using <br />
                        AWS CloudFormation
                      </h5>
                      <p className="services-txt">
                        AWS Data Pipeline is a web service that helps you
                        reliably process and move data between different AWS
                        compute and storage services, as well as on-premises
                        data sources, at specified intervals. ... AWS Data
                        Pipeline also allows you to move and process data that
                        was previously locked up in on-premises data silos.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    {/* <div class="services-icon">
                                <img src="assets/images/services/style3/1.png" alt="Services">
                            </div> */}
                    <div className="services-content">
                      <h5 className="services-title">
                        Cost-effective <br /> AWS Cloud DataOps.
                      </h5>
                      <p className="services-txt">
                        At Savrinda, we specialize in helping businesses
                        implement Low-Cost AWS Cloud DataOps solutions that
                        leverage the latest AWS technologies and best practices.
                        Our team of experts can analyze your data processing
                        needs and recommend the most cost-effective tools and
                        services for your specific use case.{" "}
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Amazon Cloud Formation and Data Pipelines" />
              <p className="mt-5">
                AWS Data Pipeline is a web service that helps you reliably
                process and move data between different AWS compute and storage
                services, as well as on-premises data sources, at specified
                intervals. ... AWS Data Pipeline also allows you to move and
                process data that was previously locked up in on-premises data
                silos.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Trusted Data Platform" />
              <p className="mt-5">
                Amazon Data Pipeline is an ETL tool that allows you to automate
                data transportation and transformation. To automate the process
                of constructing and managing pipelines, you may use the Amazon
                Management console or the AWS command line interface or API.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
