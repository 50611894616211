import React from "react";
import { Section } from "../styles";
import { Banner } from "../Home/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function BusinessConsulting() {
  const ReceivableManagementImg = require("../../assets/images/receivable-management.png");

  const services = [
    {
      title: "Budgeting and Portfolio Management in Business",
    },
    {
      title: "Comprehensive Study and Reporting on New Acquired Companies",
    },
    {
      title: "Financial Statement Analysis",
    },
    {
      title: "Accounting for Costs",
    },
    {
      title: "Intercompany/interstore transactions",
    },
    {
      title: "Partners/Investors",
    },
  ];

  return (
    <div>
      <Banner>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-50">
            <h2>Business Consulting</h2>
            <div className="col-12 col-sm-12">
              <img
                src={ReceivableManagementImg}
                alt=""
                className="w-100 py-5 pt-2"
              />
            </div>
            <p>
              With the development of optimum business strategies, our unique
              solutions deliver top and bottom line value.
            </p>
          </div>
        </div>
      </Banner>

      <Section className="">
        <div className="container ">
          <div className="row pt-5">
            <div className="col-12 col-sm-12">
              <PrimaryHeading
                primaryText="Management of Account Receivables and Account Payables"
                secondaryText=""
              />
              <p>
                As a company owner, have you ever missed a bill payment,
                suffered a late charge, or ended up delivering your product or
                service for free? These issues have a detrimental influence on
                your capacity to earn. Your company's account receivable is the
                clearest measure of its success.
              </p>
              <p>
                The account payable procedure is critical to the accuracy and
                completeness of the company's financial statements. To evaluate
                profitability, you must have more money coming in (account
                receivables) than money going out (account payables). Savrinda
                assists you in establishing correct procedures and in your
                Accounts Receivable and Accounts Payable methods.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <div className="container ">
        <Section>
          <div className="row">
            {services.map((item: any) => {
              return (
                <div className={"col-6 col-sm-4"}>
                  <GridBox style={{ height: 120 }}>
                    <b>{item.title}</b>
                  </GridBox>
                </div>
              );
            })}
          </div>
        </Section>
      </div>
    </div>
  );
}
