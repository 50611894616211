import React from "react";

import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Button from "../DesignSystem/Button";

export default function Videos() {
  return (
    <Section>
      <Primaryheading primaryText="All Technolgoy, learning and Savrinda event videos" />
      {/* Project Section Start */}
      <div className="rs-project style2 pt-50 pb-50 md-pt-50 md-pb-50">
        <div className="container">
          {/* <div className="gridFilter mb-50 md-mb-30 text-center mb-5">
            <Button className="active" data-filter="*">
              All
            </Button>
            <Button data-filter=".filter1">Data Engineer</Button>
            <Button data-filter="filter2">BigData</Button>
            <Button data-filter=".filter3">Hadoop</Button>
            <Button data-filter=".filter4">AI</Button>
          </div> */}
          <div className="row grid">
            {/* filter 1 Start */}
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter1">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/JMUxmLyrhSk"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter1">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/wTbrk0suwbg"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 1 End */}

            {/* filter 2 Start */}
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter2">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/1vbXmCrkT3Y"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 2 End */}

            {/* filter 3 Start */}
            <div className="col-lg-6 col-md-6 mb-30 grid-item filter3">
              <div className="project-item">
                <div className="project-img">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/aReuLtY0YMI"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
            {/* filter 3 End */}
          </div>
        </div>
      </div>
      {/* Project Section End */}
    </Section>
  );
}
