import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function BigData() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Hadoop & Big Data " />
                  <p className="margin-0 pt-15">
                    Big Data is transforming businesses across industries,
                    including healthcare, insurance, transportation, logistics,
                    and customer service. As a Big Data Engineer, you can embark
                    on a fascinating career that is expected to grow rapidly in
                    the coming years.
                  </p>
                  <p className="margin-0 pt-15">
                    This certification course is designed to introduce you to
                    the world of Big Data Engineering, with expert seminars and
                    Ask Me Anything sessions included. Through interactive live
                    sessions, practical labs, and real-world projects, you will
                    learn critical skills such as Big Data and Hadoop
                    frameworks, harnessing the power of AWS services, and using
                    MongoDB and other database management tools to store and
                    analyze data.
                  </p>
                  <p className="margin-0 mt-3">
                    By gaining hands-on exposure to the tools and systems used
                    by Big Data scientists and engineers, you will gain insights
                    into how Big Data can drive business outcomes.
                  </p>
                  <p className="margin-0 mt-3">
                    This course provides a comprehensive understanding of Big
                    Data engineering, making you well-equipped to thrive in this
                    exciting field.
                  </p>
                  <p className="margin-0 mt-3">
                    Through hands-on exposure with the tools and systems used by
                    big data scientists and engineers, you will get a grasp of
                    what insights big data can give.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="Big data key featrues" />
                  <p className="margin-0 pt-15">
                    By following along with the provided code, you will learn
                    how to run predictive modelling and apply graph analytics to
                    model problems. This speciality will teach you how to ask
                    the right data questions, communicate effectively with data
                    scientists, and do basic dataset exploration.
                  </p>

                  <p className="margin-0 pt-15">
                    Data mining and stream processing will be addressed as
                    fundamental methodologies. You'll also learn how to utilise
                    MapReduce and the Hadoop cluster to create and run PageRank
                    algorithms. You'll learn how big data has improved web
                    search as well as how online advertising works.
                  </p>
                  <p className="margin-0 pt-15">
                    At the end of this course, you will have a better
                    understanding of the many applications of big data methods
                    in industry and research.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Learning outcomes" />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        MapReduce expertise <br />
                        and application
                      </h5>
                      <p className="services-txt">
                        {" "}
                        The MapReduce work is broken into two phases: the Map
                        phase and the Reduce phase. Map: As the name implies,
                        its primary function is to map input data into key-value
                        pairs.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Recognizing the frequency of event <br />
                        occurrences in massive datasets
                      </h5>
                      <p className="services-txt">
                        {" "}
                        The frequency of a data value is the number of times
                        that data value appears.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Create algorithms for stream processing and
                        <br /> frequent element counting.
                      </h5>
                      <p className="services-txt">
                        {" "}
                        On the processing side, the most common stream
                        processing frameworks are Samza, Storm, and Spark
                        Streaming.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
