import React, { FunctionComponent } from "react";
import { HeaderWrapper, MenuList } from "./styles";
import Router from "../../routes";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";

const Button = loadable(() => import("../../DesignSystem/Button"));

const Header: FunctionComponent = () => {
  const logoImg = require("../../../assets/images/logo.svg");

  return (
    <>
      <HeaderWrapper>
        <nav className="navbar navbar-expand-lg w-100 container">
          <div className="container-fluid d-flex justify-content-between">
            <Link to="/Home">
              <img src={logoImg} alt="logo" className={"logo"} />
              {/* <h2 className={"logo"}>AIdata monks Inc</h2> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarScroll"
            >
              <MenuList className="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/EmpowerDevelopment">
                            Empower development
                          </Link>
                        </li>
                        <li>
                          <Link to="/Contact">Questions?</Link>
                        </li>

                        <li>
                          <Link to="/AboutUs">About Us</Link>
                        </li>
                        <li>
                          <Link to="/Careers" className="nav-item">
                            Careers
                          </Link>
                        </li>
                        <li>
                          <Link to="/Clients">Clients</Link>
                        </li>
                        <li>
                          <Link to="/Technologies" className="nav-item">
                            Technologies
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Cloud Technologies
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/AWS">Amazon Web Services</Link>
                        </li>
                        <li>
                          <Link to="/MicrosoftAzure">Microsoft Azure</Link>
                        </li>
                        <li>
                          <Link to="/Snowflake">Snowflake</Link>
                        </li>
                        <li>
                          <Link to="/Dataiku">Dataiku</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Software development
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/BigData">Big Data & Hadoop</Link>
                        </li>
                        <li>
                          <Link to="/DataScience">Data Science</Link>
                        </li>
                        <li>
                          <Link to="/MachineLearning">Machine learning</Link>
                        </li>
                        <li>
                          <Link to="/AIStrategy">Artificial Strategy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Tax Services
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/Accounting">
                            Accounting & Book Keeping
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/Payroll">
                            Payroll Processing & Reporting
                          </Link>
                        </li> */}

                        <li>
                          <Link to="/BusinessConsulting">
                            Business Consulting
                          </Link>
                        </li>

                        {/* <li>
                          <Link to="/AccountPayableManagement">
                            Account Payables Management
                          </Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarScrollingDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Knowledge
                    <i className="fa fa-chevron-down" />
                  </a>

                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarScrollingDropdown"
                  >
                    <div className="d-flex justify-content-center sub-menu-wrapper">
                      <ul className="left">
                        <li>
                          <Link to="/CaseStudies">Case Studies</Link>
                        </li>
                        <li>
                          <Link to="/Videos">Videos</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </MenuList>
              <Link to="/Contact">
                <Button title="Contact" className="ms-3">
                  Contact
                </Button>
              </Link>
            </div>
          </div>
        </nav>
      </HeaderWrapper>

      <Router />
    </>
  );
};

export default Header;
