import React from "react";
import { TwoGridBox } from "./styles";
import PrimaryHeading from "../../DesignSystem/PrimaryHeading";

export default function TwoGrid() {
  const I6 = require("../../../assets/images/6.svg");

  const services = [
    {
      title: "IT Consulting",
      para: "Our approach involves close collaboration with clients to gain an in-depth understanding of their business goals and objectives. We then create an IT strategy that is in line with their vision.",
      src: I6,
    },
    {
      title: "IT Consulting",
      para: "With our team of skilled professionals, we can assist you in developing, executing, and maintaining your IT infrastructure to ensure its security, scalability, and dependability.",
      src: I6,
    },
    {
      title: "Job Consulting",
      para: "Savrinda specializes in providing professional job consulting services to assist job seekers in navigating the job market. With our expertise,we guide you through various stages of the job search process",
      src: I6,
    },
    {
      title: "Job Support",
      para: "Savrinda is an expert in providing professional support services to job seekers, helping them navigate the job market with ease. Through their vast expertise, they assist clients through every stage of the job search process, providing guidance in areas such as resume writing, interview preparation, and job matching.",
      src: I6,
    },
    // {
    //   title: "Data Engineering Consulting",
    //   para: "We can help you extract insights from your data and make data-driven decisions to improve your business outcomes",
    //   src: I6,
    // },
  ];

  return (
    <div className={"container"}>
      <PrimaryHeading
        primaryText="Explore"
        secondaryText="our industry knowledge."
      />
      <section>
        <div className="row">
          <p className="d-flex justify-content-center text-center mb-5 px-5">
            We offer an extensive array of services that cover various areas,
            such as data engineering, cloud technologies, DevOps, artificial
            intelligence, cybersecurity, QA, coding, Salesforce, and Java.
          </p>
          {services.map((item: any) => {
            return (
              <div className={"col-12 col-sm-6"}>
                <TwoGridBox>
                  <div className="d-flex align-items-center justify-content-start w-100">
                    <img src={item.src} className="pe-3" />
                    <h5>{item.title}</h5>
                  </div>

                  <p>{item.para}</p>
                </TwoGridBox>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
}
