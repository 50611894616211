import React from "react";
import HeaderBanner from "./HeaderBanner";
import FourGrid from "../UIComponents/FourGrid";
import { Section } from "../styles";
import WorkWith from "../UIComponents/WorkWith";
import { CountableWrapper, NumberColumn, Form } from "./styles";
import TwoGrid from "../UIComponents/TwoGrid";
import Button from "../DesignSystem/Button";
import { Subscribe } from "../styles";

export default function Home() {
  const Bg1 = require("../../assets/images/5.svg");
  const FormBG = require("../../assets/images/7.svg");

  return (
    <>
      <HeaderBanner />

      <Section>
        <FourGrid />
      </Section>

      <Section>
        <WorkWith />
      </Section>

      <Section
        className="pb-5 bg-cover"
        style={{
          backgroundImage: `url(${Bg1})`,
          backgroundPosition: "center",
          width: "100%",
          backgroundRepeat: "repeat-x",
          backgroundSize: "contain",
          height: "auto",
        }}
      >
        <div className="container">
          <CountableWrapper>
            <h5>
              We are a top-tier data service provider that transforms
              evolutionary <br /> computation and analytics projects into
              reality.
            </h5>
          </CountableWrapper>
          <div className="row pb-4">
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>18</h5>
                <p>
                  We utilized our open-source importation <br />
                  platform to design custom
                  <br /> ingest funnels for each of our clients.
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>25+</h5>
                <p>
                  Our data engineering department's <br /> team of experts has
                  been handling projects with
                  <br /> dedication for more than a decade.
                </p>
              </NumberColumn>
            </div>
            <div className="col-12 col-sm-4">
              <NumberColumn>
                <h5>95%</h5>
                <p>
                  The overall retention rate for <br /> DataOps, management
                  services
                  <br /> and MLOps
                </p>
              </NumberColumn>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <TwoGrid />
      </Section>

      <Section
        className="pb-5 bg-cover"
        style={{
          backgroundImage: `url(${FormBG})`,
          backgroundPosition: "center",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "auto",
        }}
      >
        <div className="container">
          <CountableWrapper>
            <h5>
              collaborate with us to embark <br />
              on the path towards success.
            </h5>
          </CountableWrapper>
          <div className="row pb-4 pt-5 align-items-center">
            <div className="col-12 col-sm-7">
              <>
                <p className="text-white" style={{ lineHeight: 2 }}>
                  Our proficiency in delivering system updates, hosting
                  services, security, and data platform maintenance makes us the
                  perfect choice for both startups and enterprises. Utilize the
                  expertise of our specialists and cutting-edge technology to
                  explore options that other suppliers may overlook.
                </p>
              </>
            </div>
            <div className="col-12 col-sm-5">
              <Form>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <input
                      type="text"
                      placeholder="Company Name"
                      className="form-control"
                    />
                  </div>
                  <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                    <input
                      type="text"
                      placeholder="Contact Number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <textarea
                      placeholder="Write a short message here."
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row pt-3">
                  <div className="col-12 d-flex justify-content-center">
                    <Button>Let's connect</Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="d-flex justify-content-center align-items-center ">
          <Subscribe className="flex-column px-3 px-sm-0">
            <p>Subscribe to stay tuned for latest updates.</p>
            <input type="text" className="form-control" />
            <Button>Subscribe</Button>
          </Subscribe>
        </div>
      </Section>
    </>
  );
}
