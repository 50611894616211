import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function Snowflake() {
  return (
    <div>
      {/* Breadcrumbs Start */}
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img15 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="About Snowflake & Administration" />
              <p className="sub-text">
                Snowflake is a cloud-based data warehousing platform that is
                designed to assist organizations in storing, managing, and
                analyzing large amounts of data. Its range of features and
                capabilities allows users to load and process data quickly,
                efficiently, and in a cost-effective manner. The platform is
                capable of handling both structured and semi-structured data,
                making it useful for data analytics, data science, and business
                intelligence applications.
              </p>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Snowflake assistance at Savrinda Inc." />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  {/* <div className="services-icon">
                          <img src="assets/images/services/style3/2.png" alt="Services">
                      </div> */}
                  <div className="services-content">
                    <p className="services-txt">
                      At Savrinda Inc., our team of experienced Snowflake
                      administrators understands that every company has unique
                      requirements and faces specific challenges. With our
                      experience working with multiple clients, we offer
                      tailored services to help businesses maximize the benefits
                      of Snowflake data warehousing. Our team of experts can
                      provide the following services:{" "}
                    </p>
                    <ul className="icon-item mt-20">
                      <li>
                        <p className="list-text">
                          We assist companies in implementing Snowflake and
                          integrating it with other data tools for efficiency.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          We customize Snowflake architecture for businesses to
                          achieve optimal scalability and performance.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          We optimize Snowflake environment for efficient
                          resource utilization, faster query execution, and
                          improved performance.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          We ensure data safety and security by implementing
                          measures such as encryption, access control, and
                          authentication.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          We offer 24/7 support to resolve issues quickly and
                          minimize downtime.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          We help companies migrate data from legacy systems to
                          Snowflake with minimal disruption to business
                          operations.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          We provide Snowflake training to help businesses
                          maximize platform benefits and improve employee
                          proficiency.
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Working connectivity between data sources and
                          Snowflake platforms
                        </p>
                      </li>
                      <li>
                        <p className="list-text">
                          Help you save cost as you pay only for the services
                          you use
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
