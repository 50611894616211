import React from "react";
import { Banner } from "./styles";
import loadable from "@loadable/component";
import { Link } from "react-router-dom";

const Button = loadable(() => import("../DesignSystem/Button"));

export default function HeaderBanner() {
  const B1 = require("../../assets/images/1.svg");
  const B2 = require("../../assets/images/2.svg");
  const B3 = require("../../assets/images/3.svg");

  return (
    <Banner>
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h2>Contact the Best Cloud Experts</h2>
          <p>
            Savrinda transforms data management by employing an innovativenp{" "}
            <br />
            range of cloud-based software applications.
          </p>

          <section className="py-3">
            <div className="d-flex justify-content-between align-items-center row">
              <div className="d-flex flex-column col-12 col-sm-4 align-items-center">
                <img src={B3} />
                <Link to="/BusinessConsulting">
                  <Button
                    title="Modern Apps"
                    variant="secondary"
                    isHideIcon={true}
                  >
                    Business Consulting
                  </Button>
                </Link>
              </div>

              <div className="d-flex flex-column col-12 col-sm-4 align-items-center">
                <img src={B2} />
                <Link to="/Technologies">
                  <Button variant="secondary" isHideIcon={true}>
                    Technologies
                  </Button>
                </Link>
              </div>

              <div className="d-flex flex-column col-12 col-sm-4 align-items-center">
                <img src={B1} />
                <Link to="/Empowerdevelopment">
                  <Button variant="secondary" isHideIcon={true}>
                    Infrastructure
                  </Button>
                </Link>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Banner>
  );
}
