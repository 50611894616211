import styled from "styled-components";
import { displayFlex, alignedCenter } from "../../styles";
import { headerHeight } from "../../../variables";
import { theme } from "../../../theme";
import { fontSize_BASE, fontSize_SM, fontSize_XS } from "../../customFonts";

interface CustomScrollProps {
  scrolled?: boolean;
}

export const HeaderWrapper = styled.div`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  /* background-color: ${(props: any) => props.theme.primary.THEME}; */
  ${displayFlex};
  justify-content: space-between;
  align-items: center;
  height: ${headerHeight};
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: ${(props: CustomScrollProps): any =>
    props.scrolled ? "0px 0px 5px #ccc" : "none"};

  .logo {
    color: white;
    font-size: 22px;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 767px) {
    height: auto;
    padding: 0 5px;
    border-bottom: solid 1px #ccc;

    .navbar-toggler {
      background: white;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
`;

export const MenuList = styled.ul`
  list-style: none;
  ${displayFlex};
  ${alignedCenter};
  margin-bottom: 0;
  padding-left: 0;

  @media (max-width: 767px) {
    flex-flow: column;
    align-items: start;
  }

  li {
    padding: 0 6px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;

    @media (max-width: 767px) {
      flex-flow: column;
      align-items: flex-start;
      padding: 8px 0;
    }

    a {
      color: white !important;
      text-decoration: none;
      font-weight: 600;
      padding: 0;
      ${fontSize_SM};

      &:hover,
      &:focus {
        font-weight: bold;
        text-decoration: none;
        color: white;
      }

      i {
        padding-left: 8px;
      }
    }
  }

  .dropdown-menu {
    .sub-menu-wrapper {
      padding: 10px;
      padding-top: 0;
      min-width: auto;

      @media (max-width: 767px) {
        width: 100%;
      }

      a {
        color: #000 !important;
        ${fontSize_SM};

        &:hover {
          color: ${(props: any) => props.theme.danger.DANGER}!important;
        }
      }

      .left {
      }

      .right {
        border-left: solid 1px #eee;
      }

      .left,
      .right {
        padding-left: 0;
        padding: 0 12px;

        @media (max-width: 767px) {
          padding: 0 12px;
        }

        li {
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          color: ${(props: any) => props.theme.primary.THEME}!important;
        }
      }

      h5 {
        text-transform: uppercase;
        ${fontSize_SM};
        font-weight: 500;
        min-height: 20px;
        margin-bottom: 6px;
        color: #ccc;
      }

      p {
        cursor: auto;
      }
    }
  }
`;
