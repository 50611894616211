import React from "react";
import { Route, Link, Switch, Redirect } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function MicrosoftAzure() {
  return (
    <div>
      <Section className="bg-gray">
        {/* Breadcrumbs Start */}
        <div className="rs-breadcrumbs img1 machine-learning-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Microsoft Azure. The cloud AZURE innovates anywhere and creates anything." />
              <span className="sub-text">
                Azure is a public cloud computing platform developed by
                Microsoft that offers a broad range of over 200 products and
                services accessible via the internet. This platform provides
                users with a flexible, secure, and scalable environment to
                build, deploy, and manage various applications and services.
                With Azure, users can leverage cloud computing technologies to
                enhance their productivity, efficiency, and innovation.
              </span>
              <p className="sub-text">
                Since 2008, Microsoft Azure has grown to become the 2nd largest
                of the top three public cloud platforms. Just behind market
                leader Amazon Web Services (AWS) and ahead of Google Cloud
                Platform (GCP).
              </p>
            </div>
          </div>
        </div>
      </Section>
      {/* Breadcrumbs End */}

      {/* Services Section Start */}
      <Section>
        <div className="">
          <div className="container custom">
            <div className="services-box-area bg20">
              <div className="row margin-0">
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">cloud Trust</h5>
                        <p className="margin-0">
                          Receive complete security from the bottom up,
                          supported by a skilled staff, and proactive compliance
                          trusted by governments and entrepreneurs.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">Use hybrid</h5>
                        <p className="margin-0">
                          On-premises, across various clouds, and at the edge
                          are all options. We'll meet you exactly where you are.
                          Integrate and manage your environments using hybrid
                          cloud services.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item">
                      <div className="services-content">
                        <h5 className="title">Build on terms</h5>
                        <p className="margin-0">
                          With an open source commitment and support for all
                          languages and frameworks, you may develop how you want
                          and deploy wherever you want.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 padding-0">
                  <GridBox>
                    <div className="services-item no-border">
                      <div className="services-content">
                        <h5 className="title">The Future ready</h5>
                        <p className="margin-0">
                          Microsoft's continuous innovation helps your
                          development now and your product aspirations for the
                          future.
                        </p>
                      </div>
                    </div>
                  </GridBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}

      {/* Services Section Start */}
      <Section>
        <div
          id="rs-services"
          className="rs-services main-home services-style1 home-4-style bg7 pt-95 pb-100 md-pt-70 md-pb-65"
        >
          <div className="container">
            <div className="sec-title3 text-center mb-65 md-mb-45">
              <PrimaryHeading
                primaryText=" What is Microsoft Azure Cloud? &amp; Benefits of
                    Microsoft Azure Cloud"
              />
              <div className="heading-border-line" />
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">Scalability</h5>
                      <p className="services-txt">
                        {" "}
                        Azure provides a highly scalable cloud environment that
                        enables users to scale their resources up or down based
                        on their needs. This means that businesses can easily
                        respond to changes in demand and adjust their resources
                        accordingly, without any disruption to their operations.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">Flexibility</h5>
                      <p className="services-txt">
                        Azure supports multiple programming languages,
                        frameworks, and operating systems, giving users the
                        flexibility to choose the tools that best suit their
                        needs. This enables businesses to work with the tools
                        and technologies that they are familiar with, without
                        having to invest in additional infrastructure.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">Security</h5>
                      <p className="services-txt">
                        Azure provides robust security features to protect
                        against cyber threats and keep data safe. Azure's
                        security features include identity management, network
                        security, data encryption, and compliance
                        certifications, among others.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 md-mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">Cost Effective</h5>
                      <p className="services-txt">
                        {" "}
                        Azure's pay-as-you-go pricing model allows businesses to
                        pay only for the resources they use, without any upfront
                        costs. This means that businesses can reduce their costs
                        and avoid wastage by scaling their resources based on
                        their needs.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6 sm-mb-65">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">Integration</h5>
                      <p className="services-txt">
                        {" "}
                        Azure seamlessly integrates with other Microsoft
                        products and services, such as Office 365, Dynamics 365,
                        and Power Platform, enabling businesses to streamline
                        their workflows and enhance their productivity.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-6 col-md-6">
                <GridBox>
                  <div className="services-item">
                    <div className="services-text">
                      <h5 className="title">Azure Disaster Recovery</h5>
                      <p className="services-txt">
                        Microsoft helps keep critical applications running
                        during planned and unplanned outages through its
                        built-in disaster recovery tools. Users can replicate,
                        failover and recover processes as needed, while also
                        minimizing downtime by sequencing multi-tier
                        applications running on multiple virtual machines.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Services Section End */}
    </div>
  );
}
