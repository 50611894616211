import React from "react";
import { Section } from "../styles";
import { Banner } from "../Home/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { Link } from "react-router-dom";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { TwoGridBox } from "../UIComponents/TwoGrid/styles";

export default function EmpowerDevelopment() {
  const Img11 = require("../../assets/images/11.svg");
  const services = [
    {
      title: "Determining the requirements",
      para: "This involves identifying the business needs, evaluating the existing infrastructure, and identifying the necessary components for the cloud infrastructure.      ",
    },
    {
      title: "Selecting a cloud provider",
      para: "Once the requirements are determined, the next step is to select a cloud provider that best suits the business's needs. There are several cloud providers available, including Amazon Web Services (AWS), Microsoft Azure, Google Cloud, and others. ",
    },
    {
      title: "Configuring the cloud infrastructure",
      para: "Once the cloud services are selected, the infrastructure must be configured by defining the network architecture, security policies, and other parameters. ",
    },
    {
      title: "Deploying the applications",
      para: "Finally, the applications can be deployed to the cloud infrastructure, and the cloud infrastructure is ready for use. Ongoing monitoring and management are also essential to ensure the cloud infrastructure is running smoothly and meeting business needs.      ",
    },
  ];

  return (
    <div>
      <Banner>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-50">
            <h2>Cloud Infrastructure</h2>
            <p>
              Cloud computing infrastructure encompasses both hardware and
              software components necessary to support cloud computing.
            </p>
          </div>
        </div>
      </Banner>

      <Section className="">
        <div className="container ">
          <div className="row pt-5 align-items-center d-flex">
            <div className="col-12 col-sm-7">
              <PrimaryHeading
                primaryText="Cloud infrastructure to support your projects. "
                secondaryText=""
              />
              <p>
                Cloud computing infrastructure refers to the combination of
                hardware and software components required to facilitate cloud
                computing. It consists of computing resources, storage,
                networking, and an interface that enables users to access their
                virtualized resources. Cloud infrastructure is essential in
                providing on-demand access to computing resources, data storage,
                and other services that are accessible over the internet.
              </p>
              <p>
                As a top web development company, we rely on a set of
                industry-standard web tools and technologies to deliver
                high-quality web development services.
              </p>

              <p>
                Our team of developers are well-versed in the latest web
                technologies and digital trends, which allows us to implement
                them effectively for the success of our clients. We understand
                that to be the best in the industry, we need to stay up-to-date
                with the latest developments and advancements in the field of
                web development.
              </p>
            </div>
            <div className="col-12 col-sm-5">
              <img src={Img11} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </Section>

      <div className="container ">
        <Section>
          <div className="row">
            <div className={"col-12 col-sm-3"}>
              <GridBox style={{ height: 120 }}>
                <b>Network</b>
              </GridBox>
            </div>
            <div className={"col-12 col-sm-3"}>
              <GridBox style={{ height: 120 }}>
                <b>Servers</b>
              </GridBox>
            </div>
            <div className={"col-12 col-sm-3"}>
              <GridBox style={{ height: 120 }}>
                <b> Storage</b>
              </GridBox>
            </div>
            <div className={"col-12 col-sm-3"}>
              <GridBox style={{ height: 120 }}>
                <b>Virtualization</b>
              </GridBox>
            </div>
          </div>
        </Section>

        <Section>
          <PrimaryHeading primaryText="Creating a cloud infrastructure typically involves several steps, including" />
          <p className="text-center pb-4"></p>
          <div className="row">
            {services.map((item: any) => {
              return (
                <div className={"col-12 col-sm-6"}>
                  <TwoGridBox>
                    <div className="d-flex align-items-center justify-content-start w-100">
                      <h5 className="text-center">{item.title}</h5>
                    </div>
                    <p>{item.para}</p>
                  </TwoGridBox>
                </div>
              );
            })}
          </div>
        </Section>
      </div>
    </div>
  );
}
