import React from "react";
import { BgHeading, Section } from "../styles";
import { TwoGridBox } from "../UIComponents/TwoGrid/styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

const Contact = () => {
  const BG = require("../../assets/images/8.svg");
  const Img9 = require("../../assets/images/9.svg");

  return (
    <BgHeading>
      <div className="d-none d-sm-block">
        <img src={BG} alt="" />
      </div>
      <h5 className="d-none d-sm-block">Have questions - connect with us</h5>
      <div className="container">
        <Section className="d-flex justify-content-center  pt-0 pt-sm-5">
          <div className="d-flex justify-content-center">
            <div className="row margin-0">
              <div className="col-lg-12 padding-0">
                <div className="contact-address">
                  <div className="mb-5">
                    <h2 className="mt-5 mt-sm-0">Get in touch</h2>
                    <p className="margin-0">
                      Thank you for considering our IT consulting company for
                      your business needs. We welcome any questions, comments,
                      or inquiries you may have and encourage you to reach out
                      using the contact information below.
                    </p>
                    <p>
                      Our goal is to help your business succeed by offering
                      customized solutions tailored to your specific
                      requirements.
                    </p>
                    <p>
                      Whether you need help with data management, software
                      development, or technology strategy, our team of experts
                      is dedicated to providing top-notch services that keep you
                      ahead of the competition. Whether you're a small business
                      or a large corporation, we are here to help. Call us today
                      to learn more about our services and how we can help you
                      reach your business objectives.
                    </p>
                    <p>
                      We have extensive knowledge and experience working with
                      clients from a wide range of industries. We provide vital
                      services tailored to meet the unique needs of each client
                      based on their industry structure.
                    </p>
                  </div>

                  <div className="d-flex row">
                    <Primaryheading primaryText="Locations" />
                    <div className="col-12 col-sm-6">
                      <TwoGridBox>
                        <h2>USA</h2>
                        <div className="address-text">
                          299 753 Montague Expy, Milpitas, CA 95035{" "}
                        </div>
                        <div className="d-flex align-items-center my-3">
                          <i className="fa fa-phone me-3" />
                          <div className="address-text">
                            <a href="tel:+1 (531) 239-8958">
                              +1 (531) 239-8958
                            </a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-envelope me-3" />
                          <div className="address-text">
                            <a href="mailto:info@savrinda.com">
                              info@savrinda.com
                            </a>
                          </div>
                        </div>
                      </TwoGridBox>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3169.0854267806753!2d-121.89331578417627!3d37.41145567982713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcf89dd8792d5%3A0x575d4ff84386d9a1!2sThe%20Edge%20Apartments%20Milpitas!5e0!3m2!1sen!2sin!4v1678815506954!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        allowFullScreen={true}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="rounded border"
                      ></iframe>
                    </div>
                    <div className="col-12 col-sm-6">
                      <TwoGridBox>
                        <h2>India</h2>
                        <div className="address-text">
                          301 Manorama mansion Moosapet Hyderabad
                        </div>
                        <div className="d-flex align-items-center my-3">
                          <i className="fa fa-phone me-3" />
                          <div className="address-text">
                            <a href="tel:(+91)9666655510">(+91)96666 55510</a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <i className="fa fa-envelope me-3" />
                          <div className="address-text">
                            <a href="mailto:info@savrinda.com">
                              info@savrinda.com
                            </a>
                          </div>
                        </div>
                      </TwoGridBox>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.7645439431185!2d78.41579942978404!3d17.470980149737983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91a826f73d09%3A0x88747762272a2611!2sManorama%20Mansion!5e0!3m2!1sen!2sin!4v1678816320304!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        allowFullScreen={true}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        className="rounded border"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </BgHeading>
  );
};

export default Contact;
