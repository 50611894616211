import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { Link } from "react-router-dom";

export default function Technologies() {
  const Img10 = require("../../assets/images/10.svg");
  const T1 = require("../../assets/images/t1.png");
  const T2 = require("../../assets/images/t2.png");
  const T3 = require("../../assets/images/t3.png");
  const T4 = require("../../assets/images/t4.png");
  const T5 = require("../../assets/images/t5.png");
  const T6 = require("../../assets/images/t6.png");
  const T7 = require("../../assets/images/t7.png");
  const T8 = require("../../assets/images/t8.png");
  //   const T3 = require("../../assets/images/t3.png");
  //   const T3 = require("../../assets/images/t3.png");
  //   const T3 = require("../../assets/images/t3.png");
  //   const T3 = require("../../assets/images/t3.png");

  return (
    <BgHeading>
      <Section className="about-main-content bg-gray">
        <div className="container ">
          <div className="row pt-0 pt-sm-0">
            <div className="col-12 col-sm-7">
              <Primaryheading
                primaryText="Web Technologies We Rely On"
                secondaryText=""
              />
              <p>
                Our team is well-versed in the most recent online technologies
                and digital trends, allowing us to provide great web design and
                development for your new web application.
              </p>
              <p>
                We strive to be recognized as the leading web development
                company and rely on a set of industry-standard web tools and
                technologies.
              </p>
              <p>
                These tools are deeply integrated into your enterprise
                environment, and we can flexibly scale and price them to meet
                your unique needs. Furthermore, unless specified otherwise, we
                always ensure the privacy and security of your information. .
              </p>
            </div>
            <div className="col-12 col-sm-5">
              <img src={Img10} alt="" />
            </div>
          </div>
        </div>
      </Section>
      <div className="container ">
        <Section>
          <Primaryheading primaryText="Cloud Services" secondaryText="" />
          <div className="row">
            <div className={"col-6 col-sm-3"}>
              <Link to="/Aws">
                <GridBox>
                  <img src={T1} alt="" />
                  <b>AWS</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/MicrosoftAzure">
                <GridBox>
                  <img src={T2} alt="" /> <b>Microsoft Azure</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/Snowflake">
                <GridBox>
                  <img src={T3} alt="" /> <b>Snowflake</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/Dataiku">
                <GridBox>
                  <img src={T4} alt="" /> <b>Dataiku</b>
                </GridBox>
              </Link>
            </div>
          </div>
        </Section>
        <Section>
          <Primaryheading primaryText="Programming" secondaryText="" />
          <div className="row">
            <div className={"col-6 col-sm-3"}>
              <Link to="/BigData">
                <GridBox>
                  <img src={T6} alt="" /> <b>Big Data & Hadoop</b>
                </GridBox>
              </Link>
            </div>

            <div className={"col-6 col-sm-3"}>
              <Link to="/DataScience">
                <GridBox>
                  <img src={T7} alt="" /> <b>Data Science</b>
                </GridBox>
              </Link>
            </div>

            {/* <div className={"col-6 col-sm-3"}>
              <Link to="/SQLMorph">
                <GridBox>
                  <img src={T1} alt="" /> <b>SQL Morph</b>
                </GridBox>
              </Link>
            </div> */}
            <div className={"col-6 col-sm-3"}>
              <Link to="/MachineLearning">
                <GridBox>
                  <img src={T8} alt="" /> <b>Machine learning</b>
                </GridBox>
              </Link>
            </div>

            <div className={"col-6 col-sm-3"}>
              <Link to="/AIStrategy">
                <GridBox>
                  <img src={T1} alt="" /> <b>AI Strategy</b>
                </GridBox>
              </Link>
            </div>
            {/* <div className={"col-6 col-sm-3"}>
              <Link to="/DataStrategy">
                <GridBox>
                  <img src={T1} alt="" />
                  <b>Data Strategy</b>
                </GridBox>
              </Link>
            </div>
            <div className={"col-6 col-sm-3"}>
              <Link to="/DataEngineering">
                <GridBox>
                  <img src={T1} alt="" /> <b>Data Engineering</b>
                </GridBox>
              </Link>
            </div> */}
          </div>
        </Section>
      </div>
    </BgHeading>
  );
}
