import React from "react";
import { Section } from "../styles";
import { Banner } from "../Home/styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { Link } from "react-router-dom";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { TwoGridBox } from "../UIComponents/TwoGrid/styles";

export default function Accounting() {
  const Img12 = require("../../assets/images/12.svg");

  const services = [
    {
      title: "Cost Savings",
    },
    {
      title: "Outsourcing",
    },
    {
      title: "Knowledge",
    },
    {
      title: "Scalability",
    },
    {
      title: "Expertise",
    },
    {
      title: "Service",
    },
  ];

  return (
    <div>
      <Banner>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="w-50">
            <h2>Accounting Services</h2>
            <p>
              We provide high-quality customised bookkeeping, payroll, business
              counselling, and tax preparation services to your company.
            </p>
          </div>
        </div>
      </Banner>

      <Section className="">
        <div className="container ">
          <div className="row pt-5">
            <div className="col-12 col-sm-7">
              <PrimaryHeading
                primaryText="Tax preparation services of the highest calibre"
                secondaryText=""
              />
              <p>
                If you are preoccupied with operating your business and don't
                have time to handle business accounting or employ an accountant
                on your own, our outsourced accounting services are a fantastic
                choice.
              </p>
              <p>
                Here is where we can assist you by providing the best accounting
                and bookkeeping services for your business while adhering to
                GAAP and your state's accounting and taxation laws.
              </p>
              <p>
                We provide high-quality customised bookkeeping, payroll,
                business counselling, and tax preparation services to your
                company.
              </p>
              <p>
                <b>
                  Our skilled accountants provide exceptional customer service
                  and ensure that reports are delivered on time. We offer a wide
                  range of accounting and bookkeeping services, including
                  payroll processing, financial reporting, bookkeeping, APS and
                  ARS handling, and tax preparation.
                </b>
              </p>
            </div>
            <div className="col-12 col-sm-5">
              <img src={Img12} alt="" className="w-100" />
            </div>
          </div>
        </div>
      </Section>

      <div className="container ">
        <Section>
          <div className="row">
            {services.map((item: any) => {
              return (
                <div className={"col-6 col-sm-4"}>
                  <GridBox style={{ height: 120 }}>
                    <b>{item.title}</b>
                  </GridBox>
                </div>
              );
            })}
          </div>
        </Section>
      </div>
    </div>
  );
}
