import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function MachineLearning() {
  const T9 = require("../../assets/images/t9.png");

  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img5 machine-learning-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Machine Learning frameworks" />
              <p className="sub-text">
                Our team consists of proficient professionals who are dedicated
                to delivering effective IT solutions. Our group of machine
                learning engineers is always ready to assist you. They possess
                expertise in machine learning and are well-trained and
                experienced in working with extensive data.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-5 md-mb-50">
                <div className="images-part">
                  <img
                    src={T9}
                    alt="Images"
                    className="w-100"
                    style={{ opacity: 0.8 }}
                  />
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-6 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText=" Machine Learning" />
                  <p className="margin-0 pt-15">
                    To succeed in the market, it is vital for a company to
                    prioritize customer satisfaction by understanding their
                    preferences and behavior. Machine Learning and Artificial
                    Intelligence technologies play a critical role in achieving
                    this goal.
                  </p>
                  <p className="margin-0 pt-15">
                    By leveraging these technologies, businesses can gather data
                    on customer trends and gain insights into the preferences
                    and expectations of both current and potential customers.
                  </p>
                  <p className="margin-0 mt-3">
                    Sophisticated applications and tools are essential for
                    analyzing and implementing the latest algorithms to
                    accomplish various objectives. Skilled Machine Learning
                    engineers are essential to achieving these objectives.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-about pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container custom">
            <div className="row">
              <div className="col-lg-12 pl-60 md-pl-15">
                <div className="sec-title">
                  <PrimaryHeading primaryText="In what ways can Savrinda provide assistance to you?" />

                  <p className="margin-0 pt-15">
                    Our team consists of proficient professionals who are
                    dedicated to delivering effective IT solutions. Our group of
                    machine learning engineers is always ready to assist you.
                    They possess expertise in machine learning and are
                    well-trained and experienced in working with extensive data.{" "}
                  </p>
                  <ul className="margin-0 mt-4">
                    <h5>This is what sets them apart.</h5>
                    <li className="margin-0 mt-3">
                      They gather relevant data and utilize ML tools to
                      construct models that produce superior outcomes. They
                      prioritize present value while also taking into account
                      the future potential of Machine Learning.
                    </li>
                    <li className="margin-0 mt-3">
                      Images, audio, and video often contain large amounts of
                      data. Our ML engineers maximize the use of algorithms and
                      data pipelines to develop programs that handle complex
                      data accurately and efficiently. These programs are highly
                      responsive and free of errors.
                    </li>
                    <li className="margin-0 mt-3">
                      They design MLOps platforms that are extremely responsive
                      and capable of executing various Machine Learning data
                      operations, including the creation of serverless
                      deployment models.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Here is what proficient Machine Learning engineers do." />
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Decision making <br /> technology
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Produce data that is employed to enhance a business's
                        growth through technology that leverages data-driven
                        decision-making.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Accurate <br /> results
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Ensure accurate results by guaranteeing the appropriate
                        and effective utilization of data models.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Work with the <br /> ML frameworks
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Work closely and precisely with ML frameworks, tools,
                        and models.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 md-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Working on large <br /> data
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Continuously working with large data files and
                        conducting data research to extract the most up-to-date
                        information that is beneficial to the company.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
              <div className="col-lg-4 col-md-6 sm-mb-50">
                <GridBox>
                  <div className="services-item">
                    <div className="services-content">
                      <h5 className="services-title">
                        Avoid all <br /> problems
                      </h5>
                      <p className="services-txt">
                        {" "}
                        Identify and address issues promptly to prevent any
                        potential risks or problems from occurring.
                      </p>
                    </div>
                  </div>
                </GridBox>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
