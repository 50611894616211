import React from "react";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import { Section } from "../styles";

export default function AIStrategy() {
  // const T9 = require("../../assets/images/t9.png");

  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img10 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="What is Artificial Intelligence (AI)?" />
              <p className="sub-text">
                Artificial Intelligence (AI) is a scientific field that focuses
                on enabling machines to solve complex problems using methods
                that resemble human intelligence. To achieve this, AI involves
                adopting human-like characteristics and translating them into
                algorithms that can be implemented on a computer.
              </p>
              <p className="sub-text">
                AI involves a wide range of techniques, such as machine
                learning, natural language processing, computer vision, and
                robotics, to develop systems that can perform tasks that
                typically require human intelligence. These systems are designed
                to learn from data, recognize patterns, and make predictions or
                decisions based on the information available.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div
          id="rs-about"
          className="rs-about main-home pt-50 pb-100 md-pt-70 md-pb-70"
        >
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-5 md-mb-50">
                <div className="images-part">
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/Cx5aNwnZYDc"
                    title="YouTube video player"
                    frameBorder={0}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="sec-title3">
                  <PrimaryHeading primaryText="Artificial intelligence (AI) and its Importance:" />

                  <p className="">
                    Software equipped with Artificial Intelligence (AI) can now
                    perform tasks that have traditionally required human
                    capabilities such as understanding, planning, reasoning,
                    communication, and perception. Advancements in AI technology
                    have made it possible for machines to execute these tasks
                    with increasing effectiveness, efficiency, and
                    cost-effectiveness.
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">
                        The level of flexibility in the development of AI can
                        vary, depending on the specific requirements of the
                        project or application. This can have an impact on the
                        appearance of the system's intelligent behavior, as a
                        rigid approach may result in behavior that seems less
                        natural or human-like, while a more adaptable approach
                        may lead to behavior that is more responsive and suited
                        to different situations.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Depending on the needs, an efficient strategy may be
                        selected, which determines how artificial the
                        intelligent behaviour seems.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Artificial intelligence (AI) is a broad field of
                        computer science concerned with creating intelligent
                        computers capable of doing activities that normally
                        require human intelligence.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      {/* <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="4 key AI concepts you need to understand and Face recognition." />
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Face recognition </a>
                    </h2>
                    <p className="services-txt">
                      {" "}
                      It is a technology that uses algorithms and machine
                      learning techniques to identify and verify a person's
                      identity based on their facial features, such as the
                      distance between the eyes, the shape of the jawline, and
                      the contours of the face.
                    </p>
                    <p className="services-txt"> </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Neural Networks</a>
                    </h2>
                    <p className="services-txt">
                      Neural networks are used in machine learning to analyze
                      and learn from complex data sets, and are particularly
                      well-suited for tasks such as image and speech
                      recognition. The strength of neural networks lies in their
                      ability to detect patterns and relationships in data, even
                      when the data is noisy, incomplete, or ambiguous.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Machine learning</a>
                    </h2>
                    <p className="services-txt">
                      Machine learning is a type of artificial intelligence that
                      involves training computers to learn from data, without
                      being explicitly programmed. It involves using algorithms
                      to analyze and identify patterns in data, and then using
                      these patterns to make predictions or decisions. <br />
                      Machine learning has numerous practical applications,
                      including image recognition, natural language processing,
                      recommendation systems, predictive analytics, fraud
                      detection, and autonomous vehicles. It has become an
                      essential tool for businesses and organizations looking to
                      make data-driven decisions and gain insights from large
                      amounts of complex data.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-5">
                <div className="services-item">
                  <div className="services-content">
                    <h2 className="services-title">
                      <a href="">Natural Language Processing</a>
                    </h2>
                    <p className="services-txt">
                      Natural language processing (NLP) is a subfield of
                      artificial intelligence that focuses on enabling computers
                      to understand, interpret, and generate human language. It
                      involves developing algorithms and computational models
                      that can analyze and interpret large amounts of text or
                      speech data.
                    </p>
                    <p className="services-txt">
                      NLP has many practical applications, including language
                      translation, sentiment analysis, chatbots, speech
                      recognition, and text summarization. It can be used to
                      analyze and understand both written and spoken language,
                      and can be trained to recognize patterns and relationships
                      in language data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section> */}
    </div>
  );
}
