import React from "react";
import { Section } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";

export default function DataScience() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="">
          <div className="container">
            <div className="breadcrumbs-inner">
              <PrimaryHeading primaryText="Data Science?" />

              <p className="sub-text">
                Data science has seen a significant rise in demand over the past
                decade, as organizations seek professionals who possess the
                skills to analyze data and communicate insights effectively to
                enable data-driven decision making. <br />
                This trend is expected to continue as businesses across various
                industries increasingly rely on data to inform strategic
                choices. As a result, the need for data scientists who can
                extract meaningful insights from large data sets using tools
                such as statistical modeling, machine learning, and data
                visualization has never been higher.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 gray-bg pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading primaryText="Adoption of DATA SCIENCE in Finance!" />

              <p className="mt-20">
                According to recent statistics, the adoption rate of Analytics
                and Data Science in large Indian firms has increased to{" "}
                <b className="text-danger" style={{ fontSize: "40px" }}>
                  {" "}
                  74.5%
                </b>{" "}
                in 2021, which is higher than the adoption rate of 70% in 2019.
                This indicates a growing demand for professionals with skills in
                analytics and data science in India's job market. If you are
                looking to start a career in this field or transition to a new
                one, pursuing a professional certificate in data science can
                help you become work-ready. You can learn at your own pace and
                convenience, with the flexibility to suspend your studies or
                cancel your membership at any time.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 md-mb-50">
                <div className="services-item">
                  <div className="services-content">
                    <p className="services-title">
                      This Professional Certificate program focuses on applied
                      learning, with a strong emphasis on hands-on labs on the
                      Cloud in all courses except the first one. These labs
                      provide practical skills that can be directly applied to
                      real-world situations, making you job-ready and
                      competitive in the field of data science.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 mb-50">
                <div className="services-item">
                  <div className="services-content">
                    <p>
                      The emphasis on applied learning is considerable in this
                      Professional Certificate. All subsequent courses, except
                      for the first, involve a series of hands-on labs on the
                      Cloud that will provide you with practical skills that can
                      apply to real-world situations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-50 pb-50 md-pt-50 md-pb-50">
          <div className="container">
            <div className="sec-title mb-60">
              <PrimaryHeading
                primaryText="   How will Data Science change the world?
                        "
              />

              <div className="mt-30 mb-30">
                <img
                  className="hovers-logos rs-grid-img w-50"
                  src="assets/images/pages/data-science/4.png"
                  alt=""
                />
              </div>
              <div className="mt-30 mb-30">
                <iframe
                  width="100%"
                  height="500"
                  src="https://www.youtube.com/embed/_fjUaDj77NI"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
