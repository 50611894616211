import styled from "styled-components";
import { headerBoxShadow, topBarHeight } from "../../../variables";
import { flexCenter, displayFlex } from "../../styles";
import { fontSize_SM, fontSize_XXL } from "../../customFonts";

export const FooterSection = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.primary.THEME};
  color: white;
  padding: 30px 0;

  h5 {
    padding-bottom: 26px;
    font-weight: 700;
    ${fontSize_XXL};
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      padding-bottom: 16px;
    }
  }

  p,
  li {
    font-weight: 400;
    ${fontSize_SM};
  }

  a {
    color: white;
  }
`;

export const CopyrightBar = styled.div`
  background-color: #22242a;
  ${flexCenter};
  color: white;
  padding: 14px 0;

  p,
  a {
    text-align: center;
    margin: 0;
    color: white;
  }
`;
