import React from "react";
import { Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function Dataiku() {
  return (
    <div>
      <Section className="bg-gray">
        <div className="rs-breadcrumbs img9 aws-banner">
          <div className="container">
            <div className="breadcrumbs-inner">
              <Primaryheading primaryText="Dataiku - An Expertise Tool" />
              <span className="sub-text">
                Dataiku is a modern platform that combines the use of Artificial
                Intelligence (AI) and data to deliver exceptional business
                outcomes. It offers an integrated virtual environment that
                allows users to work as they desire.
              </span>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div>
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="sec-title3">
                  <Primaryheading
                    primaryText="                                    What makes us different?
                            "
                  />

                  <p className="margin-0 pb-40">
                    At Savrinda Inc, we offer top-notch guidance for Dataiku to
                    our clients, simplifying the implementation and execution
                    process. .{" "}
                  </p>
                  <ul className="icon-item">
                    <li>
                      <p className="list-text">
                        Our team of professional strategists develops
                        comprehensive guides to train your team on various
                        Dataiku models.
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        We provide 24/7 support and ensure that your Dataiku
                        platform is always running smoothly. Our administration
                        techniques are highly effective in ensuring optimal
                        performance..{" "}
                      </p>
                    </li>
                    <li>
                      <p className="list-text">
                        Our team at Savrinda uses a variety of AI tools and
                        strategies to deliver high-quality performance and
                        services to our clients in the field of machine
                        learning. For further information, feel free to reach
                        out to us.{" "}
                      </p>
                    </li>
                  </ul>
                  <p className="margin-0 pb-40">
                    For more information and assistance, you can contact
                    Savrinda.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-100 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText="Automate Analytics for a Self-Service Company" />

              <p className="services-txt mt-30 mb-30">
                Dataiku is a powerful platform designed to automate analytical
                tasks and optimize workflows for businesses. It offers an array
                of features and capabilities that allow users to schedule and
                automate recurring tasks without relying on IT teams or data
                scientists. By automating data processing tasks like data
                cleansing, integration, and transformation, businesses can free
                up time to focus on more impactful and productive tasks such as
                data analysis and modeling.
                <br /> Dataiku also enables self-service analytics, empowering
                business users to access and analyze data without needing
                technical expertise. This democratization of data empowers users
                to make informed decisions based on insights, leading to better
                business outcomes. Dataiku supports various frameworks such as
                JavaScript, R Shiny, Bokeh, and Dash to develop applications
                with insights that drive good customer experiences and
                efficiency.
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className="rs-services style3 pt-20 pb-10">
          <div className="container">
            <div className="sec-title mb-60">
              <Primaryheading primaryText="Web Apps and More" />
              <p className="services-txt mt-30 mb-30">
                Dataiku supports various leading frameworks such as JavaScript,
                R Shiny, Bokeh, Dash, and more, enabling organizations to build
                applications with the insights they need to enhance customer
                experiences and improve efficiency. This provides organizations
                with multiple ways to develop applications that can automate
                analytical tasks and drive better business results, without
                relying on IT teams or data science experts. By leveraging these
                frameworks, users can build customized applications that suit
                their unique requirements and help them gain actionable insights
                from their data. With Dataiku's support for a wide range of
                frameworks, users can choose the best technology for their
                specific use case and develop applications faster and more
                efficiently.
              </p>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
