import styled from "styled-components/macro";
import {
  ActiveStyleReturnType,
  HoverStyleReturnType,
  StyleReturnType,
} from "./helpers";
import { fontSize_SM } from "../../customFonts";

// This interface is for Styles passed from Function Component
interface Styles {
  generalStyle: StyleReturnType;
  hoverStyle: HoverStyleReturnType;
  activeStyle: ActiveStyleReturnType;
}

export const Button = styled.button<Styles>`
  cursor: pointer;
  border-radius: 36px;
  ${fontSize_SM};
  margin: 0 5px;
  min-width: ${(props: Styles): string => props.generalStyle.minWidth};
  max-width: ${(props: Styles): string => props.generalStyle.maxWidth};
  min-height: ${(props: Styles): string => props.generalStyle.minHeight};
  color: ${(props: Styles): string => props.generalStyle.color};
  opacity: ${(props: Styles): number => props.generalStyle.opacity};
  pointer-events: ${(props: Styles): string =>
    props.generalStyle.pointerEvents};
  background-color: ${(props: Styles): string =>
    props.generalStyle.backgroundColor};
  border: ${(props: Styles): string => props.generalStyle.border};
  border-color: ${(props: Styles): string => props.generalStyle.borderColor};
  box-shadow: ${(props: Styles): string => props.generalStyle.boxShadow};
  font-weight: 400;
  padding: 5px 10px;

  img {
    margin-right: 5px;
  }

  &:hover {
    color: ${(props: Styles): string => props.hoverStyle.color};
    background-color: ${(props: Styles): string =>
      props.hoverStyle.backgroundColor};
    /* border: ${(props: Styles): string => props.hoverStyle.border};
    border-color: ${(props: Styles): string => props.hoverStyle.borderColor}; */
  }

  &:active,
  &:focus {
    box-shadow: ${(props: Styles): string => props.activeStyle.boxShadow};
    outline: none;
    color: ${(props: Styles): string => props.activeStyle.color};
    background-color: ${(props: Styles): string =>
      props.activeStyle.backgroundColor};
    border: ${(props: Styles): string => props.activeStyle.border};
    border-color: ${(props: Styles): string => props.activeStyle.borderColor};
  }
`;

// import styled from "styled-components";

// export const ThemeButton = styled.button`
//   min-width: 120px;
//   max-width: 156px;
//   height: 36px;
//   color: white;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #e8292e;
//   box-shadow: 0px 1px 2px rgba(105, 81, 255, 0.05);
//   border-radius: 36px;
//   border: none;
//   font-size: 14px;

//   :hover {
//     box-shadow: 0 15px 10px rgba(32, 41, 83, 0.06),
//       0 15px 25px rgba(45, 83, 254, 0.1);
//   }
// `;
