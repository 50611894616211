import React from "react";
import { Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";

export default function CaseStudies() {
  return (
    <div>
      <>
        <div className="container">
          <Section>
            <Primaryheading primaryText="Case Studies" />
            <div className="row">
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Data Engineering Case Study</h5>
                  <p>
                    Our team of data engineering experts assisted a prominent
                    e-commerce enterprise in enhancing their data infrastructure
                    and data processing capabilities. Through the design and
                    implementation of a sturdy data pipeline, our team
                    facilitated the processing of millions of transactions with
                    ease. Additionally, we optimized their data storage and
                    retrieval procedures, which empowered the company to extract
                    valuable insights from their data and make informed
                    decisions. This resulted in a notable improvement in their
                    operational efficiency and a corresponding increase in
                    revenue.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>E-commerce Case Study</h5>
                  <p>
                    Our team of software developers collaborated with a startup
                    e-commerce enterprise to create a bespoke e-commerce
                    platform tailored to their unique requirements. By utilizing
                    our expertise, we designed and constructed a scalable
                    platform capable of processing significant levels of traffic
                    and transactions. Moreover, we seamlessly integrated several
                    third-party services such as payment gateways and shipping
                    providers to offer a streamlined customer experience. The
                    resulting platform facilitated the establishment of a robust
                    online presence and drove sales growth for the company.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Technology Migration Case Study</h5>
                  <p>
                    Our team of experts supported a significant financial
                    services organization in transitioning their legacy systems
                    to a contemporary cloud-based platform. Through our adept
                    strategy, we designed and executed a seamless migration plan
                    that guaranteed minimal downtime and preserved data
                    integrity. Furthermore, we furnished the company's staff
                    with training and assistance to ensure a smooth and
                    effective transition. As a direct outcome of our efforts,
                    the company streamlined its operations and experienced a
                    reduction in costs.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Management Consulting Case Study</h5>
                  <p>
                    We rendered management consulting services to a mid-sized
                    manufacturing enterprise experiencing challenges with low
                    profitability. Our team of consultants conducted an in-depth
                    analysis of the company's processes and pinpointed areas
                    requiring improvement. Subsequently, we devised a
                    comprehensive strategy that emphasized optimizing the supply
                    chain, reducing waste, and refining production processes.
                    The implementation of this strategy enabled the company to
                    achieve an increase in profitability and gain a competitive
                    advantage in the market.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-12"}>
                <GridBox>
                  <h5>Data Validation Case Study</h5>
                  <p>
                    Our team of data validation experts collaborated with a
                    healthcare organization to authenticate their data and
                    guarantee adherence to regulatory mandates. We devised and
                    executed a comprehensive validation strategy comprising data
                    profiling, data quality checks, and data completeness
                    checks. Additionally, we furnished the company with
                    recommendations to enhance their data management processes.
                    The successful implementation of our strategy resulted in a
                    marked improvement in the company's data quality and
                    compliance with regulatory requirements.
                  </p>
                </GridBox>
              </div>
            </div>
          </Section>
        </div>
      </>
    </div>
  );
}
