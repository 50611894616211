import styled from "styled-components";
import { fontSize_XL, fontSize_XXXL } from "../customFonts";

export const WhyWorkWithUs = styled.div`
  h3 {
    ${fontSize_XXXL};
    font-weight: bold;
  }

  h4 {
    ${fontSize_XL};
    font-weight: 600;
    margin-bottom: 14px;
  }

  h4:after {
    content: "";
    border-bottom: 8px solid ${(props: any) => props.theme.danger.DANGER};
    width: 32px;
    border-radius: 4.5px;
    display: block;
    margin: 0 auto;
    margin-top: -14px;
    position: absolute;
    margin-left: -46px;
  }
`;

export const SectionHiring = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .section-positions-list {
    .section-each-position {
      border-bottom: 1px solid #cdcdcd;
      padding-bottom: 40px;
      padding-top: 40px;

      h2 {
        padding-bottom: 20px;
        font-weight: bold;
        ${fontSize_XL};
      }
    }
  }
`;

export const SectionEachPosition = styled.div`
  h2 {
    color: ${(props: any) => props.theme.primary.THEME};
  }
`;
