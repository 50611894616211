import { theme } from "../../../theme";
import { variant as buttonVariants } from "./buttonVariants";

// Style Parameters to be applied when we hover over a button
export type HoverStyleReturnType = {
  backgroundColor: string;
  color: string;
  border: string;
  borderColor: string;
  boxShadow: string;
  fontWeight: string;
};

export type ActiveStyleReturnType = {
  backgroundColor: string;
  color: string;
  border: string;
  borderColor: string;
  boxShadow: string;
  fontWeight: string;
};

// Size of button
export type size = "sm" | "lg";

// Style Parameters to be applied on button reuses the return types of hover
export interface StyleReturnType extends HoverStyleReturnType {
  minHeight: string;
  height: number;
  minWidth: string;
  opacity: number;
  pointerEvents: string;
  fontWeight: string;
  maxWidth: string;
}

export interface StyleReturnType extends ActiveStyleReturnType {
  height: number;
  minHeight: string;
  minWidth: string;
  opacity: number;
  pointerEvents: string;
  fontWeight: string;
  maxWidth: string;
}

export const defaultStyle = {
  backgroundColor: theme.danger.DANGER,
  color: "white",
  height: 36,
  minHeight: "36px",
  minWidth: "90px",
  opacity: 1,
  pointerEvents: "all",
  border: "1px solid",
  borderColor: "transparent",
  boxShadow: " 0 1px 3px rgba(0, 0, 0, 0.08)",
  fontWeight: "600",
  maxWidth: "auto",
};

// This returns general style for button as a dictionary
export const getStyle = (
  variant: buttonVariants,
  size: size | undefined,
  disabled: boolean | undefined
): StyleReturnType => {
  const additionalStyle = { ...defaultStyle };

  if (disabled === true) {
    additionalStyle.opacity = 0.5;
    additionalStyle.pointerEvents = "none";
    additionalStyle.backgroundColor = theme.primary.THEME;
    additionalStyle.color = "#CCCCCC";
    additionalStyle.fontWeight = "600";
  }

  if (size === "lg") {
    additionalStyle.height = 36;
  }

  if (size === "sm") {
    additionalStyle.height = 36;
  }

  const style: { [variant: string]: StyleReturnType } = {
    primary: {
      ...additionalStyle,
      backgroundColor: theme.danger.DANGER,
      fontWeight: "400",
    },
    secondary: {
      ...additionalStyle,
      backgroundColor: "white",
      color: theme.primary.THEME,
      fontWeight: "600",
    },
  };

  return style[variant];
};

// This returns hover style for button as a dictionary
export const getHoverStyle = (
  variant: buttonVariants
): HoverStyleReturnType => {
  const additionalStyle = {
    border: "1px solid",
    borderColor: theme.primary.THEME,
    boxShadow: " 0 4px 4px rgba(0, 0, 0, 0.09)",
  };
  const style: { [variant: string]: HoverStyleReturnType } = {
    primary: {
      ...additionalStyle,
      backgroundColor: "#000",
      color: "white",
      border: "none",
      fontWeight: "400",
    },
    secondary: {
      ...additionalStyle,
      backgroundColor: "white",
      color: "#000",
      borderColor: "#000",
      fontWeight: "600",
    },
  };

  return style[variant];
};

// This returns active/focus style for button as a dictionary
export const getActiveStyle = (
  variant: buttonVariants
): ActiveStyleReturnType => {
  const additionalStyle = {
    border: "1px solid",
    borderColor: theme.primary.THEME,
    boxShadow: " 0 4px 4px rgba(0, 0, 0, 0.09)",
  };
  const style: { [variant: string]: ActiveStyleReturnType } = {
    primary: {
      ...additionalStyle,
      backgroundColor: "#000",
      color: "white",
      border: "none",
      fontWeight: "400",
    },
    secondary: {
      ...additionalStyle,
      backgroundColor: "white",
      color: "#000",
      borderColor: "#000",
      fontWeight: "600",
    },
  };

  return style[variant];
};
