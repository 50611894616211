import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Button from "../DesignSystem/Button";
import { WhyWorkWithUs, SectionHiring, SectionEachPosition } from "./styles";
import { Link } from "react-router-dom";

export default function Careers() {
  const positionsData = [
    {
      designation: "Data Engineer",
      description:
        "We are in search of a proficient data engineer possessing the ability to design, construct, and sustain data pipelines and data warehouses. The ideal candidate should possess experience with ETL tools, data modelling, and SQL.",
    },
    {
      designation: "Hadoop Developer",
      description:
        "We are currently seeking a Hadoop developer with the expertise to develop, maintain, and provide support for our Hadoop infrastructure. The ideal candidate should possess experience in Hadoop, Hive, Pig, and Spark.",
    },
    {
      designation: "Cloud Technologies Expert",
      description:
        "We are seeking a Cloud Technologies Expert with the ability to assist our clients in migrating their applications and infrastructure to the cloud. The ideal candidate should possess experience with prominent cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP).",
    },
    {
      designation: "Azure Architect",
      description:
        "We are currently seeking an experienced Azure Architect capable of designing and implementing Azure-based solutions for our clients. The ideal candidate should possess expertise in Azure services, including Azure App Service, Azure Functions, and Azure Kubernetes Service (AKS).",
    },
    {
      designation: "AWS Developer",
      description:
        "We are seeking an experienced AWS developer who can design, develop, and sustain AWS-based applications. The ideal candidate should possess experience in AWS services such as EC2, S3, and Lambda.",
    },
    {
      designation: "GCP Engineer",
      description:
        "We are looking for a skilled GCP engineer who can design and implement GCP-based solutions for our clients. The ideal candidate should have prior experience with GCP services such as Compute Engine, Cloud Storage, and BigQuery.",
    },
    {
      designation: "DevOps Engineer",
      description:
        "We are currently searching for a skilled DevOps engineer who can design, develop, and sustain our continuous integration and deployment pipelines. The ideal candidate should possess experience with containerization technologies such as Docker and Kubernetes, as well as with CI/CD tools like Jenkins or GitLab.",
    },
    {
      designation: "Machine Learning Engineer",
      description:
        "We are currently in search of a skilled Machine Learning Engineer with the expertise to design, develop, and deploy ML models for our clients. The ideal candidate should possess experience with Python, TensorFlow, and PyTorch.",
    },
    {
      designation: "AI Developer",
      description:
        "We are seeking an AI Developer capable of designing, developing, and deploying AI-based applications. The ideal candidate should possess experience with AI frameworks such as Keras and OpenAI.",
    },
    {
      designation: "Data Scientist",
      description:
        "We are currently searching for a skilled Data Scientist with the proficiency to analyze data and develop insights for our clients. The ideal candidate should possess experience with statistical analysis, data visualization, and machine learning.",
    },
    {
      designation: "Salesforce Developer",
      description:
        "We are currently seeking a skilled Salesforce developer with the ability to design, develop, and maintain Salesforce applications. The ideal candidate should possess experience with Apex, Visualforce, and Salesforce Lightning.",
    },
    {
      designation: "Java Developer",
      description:
        "We are currently looking for a Java Developer who can design, develop, and maintain Java applications. The ideal candidate should have experience with Java, Spring, and Hibernate frameworks.",
    },
    {
      designation: "QA Analyst",
      description:
        "We are seeking a skilled QA Analyst with the ability to develop and execute test plans, ensuring that software applications meet high-quality standards. The ideal candidate should possess experience with test automation, manual testing, and defect tracking..",
    },
    {
      designation: "Front-End Developer",
      description:
        "We are seeking a skilled Front-End Developer who has expertise in designing, developing, and maintaining user interfaces for web applications. The ideal candidate should have experience working with HTML, CSS, and JavaScript.",
    },
    {
      designation: "Business Analyst",
      description:
        "We are looking for an experienced Business Analyst who can analyze business requirements, collaborate with stakeholders to define business processes and systems, and work with Agile methodologies. The ideal candidate should also have experience in requirements gathering and process modeling.",
    },
    {
      designation: "Cyber Security Expert",
      description:
        "We are seeking a skilled Cyber Security Specialist who can develop and maintain effective security measures to safeguard our company's systems and data. The ideal candidate should have experience with security frameworks, risk assessments, and security audits.",
    },
    {
      designation: "Project Manager",
      description:
        "We are looking for an experienced individual who can manage and lead our team of developers, analysts, and engineers. The ideal candidate should have a strong background in team management, project management, and software development.",
    },
  ];
  return (
    <div>
      <BgHeading>
        <Section className="bg-gray py-0">
          <div className="container">
            <Section className="about-main-content">
              <Primaryheading primaryText="Careers" secondaryText="" />
              <div className="d-flex justify-content-center flex-column align-items-center">
                <p className="text-center">
                  Starlight introduces branded products using a proprietary
                  system flow <br />
                  that maximizes return on investment in the digital and
                  advertising realms.
                  <br />
                </p>
                <Link to="/Contact">
                  <Button className="mt-3">Join With Us</Button>
                </Link>
              </div>
            </Section>
            <WhyWorkWithUs className="d-flex justify-content-center flex-column align-items-center mt-0 pb-3">
              <h3 className="pb-5">
                "What sets us apart from other options and why might you
                consider partnering with our company?"
              </h3>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Flexibility</h4>
                    <p>
                      Savrinda provides flexible solutions that can be tailored
                      to your organization's needs and budget. They can work
                      with you on a project basis or on a long-term basis,
                      depending on your requirements.
                    </p>
                  </GridBox>
                </div>
                <div className="col-12 col-sm-6">
                  <GridBox>
                    <h4>Efficiency</h4>
                    <p>
                      Savrinda helps your organization save time and resources
                      by providing specialized expertise and skills that may not
                      be available in-house. They can help you streamline your
                      operations, optimize your processes, and implement best
                      practices that can improve your overall efficiency.
                    </p>
                  </GridBox>
                </div>
              </div>
            </WhyWorkWithUs>
          </div>
        </Section>

        <Section className="">
          <div className="container">
            <SectionHiring>
              <p className="badge">Open positions</p>
              <Primaryheading primaryText="We're Hiring" secondaryText="" />
              <p className="text-center">
                We are excited to share our current job opportunities with you!
                As a rapidly expanding IT consulting company, we are constantly
                seeking highly skilled and motivated individuals to join our
                team. Please find below the list of our current job openings.
              </p>
              <div className="section-positions-list mt-5">
                {positionsData.map((item: any) => {
                  return (
                    <SectionEachPosition className="section-each-position">
                      <h2>{item.designation}</h2>
                      <div className="row d-flex justify-content-between align-items-center">
                        <p className="col-12 col-sm-9 m-0">
                          {item.description}
                        </p>
                        <div className="col-12 col-sm-3 d-flex justify-content-end">
                          <Link to="/Contact">
                            <Button>APPLY NOW</Button>
                          </Link>
                        </div>
                      </div>
                    </SectionEachPosition>
                  );
                })}
              </div>
            </SectionHiring>
          </div>
        </Section>
      </BgHeading>
    </div>
  );
}
