import styled from "styled-components";
import { flexCenter } from "../../styles";
import {
  fontSize_BASE,
  fontSize_SM,
  fontSize_XL,
  fontSize_XXL,
} from "../../customFonts";

export const GridBox = styled.div`
  padding: 18px;
  ${flexCenter};
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;
  height: 230px;

  @media (max-width: 767px) {
    width: 100%;
  }

  h5,
  h2 {
    ${fontSize_BASE};
    line-height: 24px;
    text-align: center;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  h2 {
    color: #000;
    text-align: left;
    ${fontSize_XXL};
    padding-bottom: 14px;
  }

  p {
    text-align: center;

    @media (max-width: 480px) {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
    }
  }

  b {
    ${fontSize_BASE};
    font-weight: 900;
  }

  img {
    padding-bottom: 26px;
    width: 90px;
  }

  h5 {
    font-weight: bold;
  }
`;
