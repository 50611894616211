import React from "react";
import { BgHeading, Section } from "../styles";
import Primaryheading from "../DesignSystem/PrimaryHeading";
import { GridBox } from "../UIComponents/FourGrid/styles";

export default function AboutUs() {
  const BG = require("../../assets/images/8.svg");
  const Img9 = require("../../assets/images/9.svg");
  return (
    <div>
      <BgHeading>
        <img src={BG} alt="" />

        <div className="container">
          <Section className="about-main-content">
            <h5>About Savrinda Inc.</h5>
            <p>
              Savrinda is a leading IT solutions provider that specializes in AI
              and machine learning services. With five years of experience, we
              aim to provide our customers with superior solutions. We are
              available to help our clients in any way we can. We are trusted by
              leading companies from various industries.
            </p>
            <div className="row pt-5">
              <div className="col-12 col-sm-7">
                <Primaryheading
                  primaryText="We can assist you in automating your ideas and turning them into reality."
                  secondaryText=""
                />
                <p>
                  Use AI and machine learning technologies to keep up with the
                  ever-changing world of technology. Data-driven techniques are
                  the way of the future in business optimization. Be certain
                  that your organization embraces and capitalizes on this
                  change.
                </p>
                <p>
                  If you're seeking a team to assist you with data-driven
                  decision-making procedures to grow your client base, you've
                  come to the correct place. We value quality above quantity,
                  which is why Savrinda is among the best in the market.
                </p>
                <p>
                  We are here to assist our clients in any way we can, and we
                  are trusted by major firms from various industries.
                </p>
                <p>
                  We update our tools in response to evolving technologies. This
                  also helps us keep current on our clients' demands. Our staff
                  supports you in effectively managing and utilizing data for
                  exceptional business success.{" "}
                </p>
                <p>
                  We help companies construct efficient cloud systems from the
                  ground up. Our data engineers, architects, and strategists
                  work meticulously to get 100% favorable results.
                </p>
                <p>
                  Whether you're seeking IoT data jobs, data engineers, or AI
                  strategists, Savrinda can help. We are eager to meet our
                  clients' difficulties.
                </p>
              </div>
              <div className="col-12 col-sm-5">
                <img src={Img9} alt="" />
              </div>
            </div>
          </Section>
          <Section>
            <div className="row">
              <Primaryheading primaryText="Why Choose Us" secondaryText="" />
              <div className={"col-12 col-sm-4"}>
                <GridBox>
                  <h2>Expertise</h2>
                  <p>
                    Our team is made up of specialists that have years of
                    experience and extensive expertise in their respective
                    disciplines.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-4"}>
                <GridBox>
                  <h2>Results-oriented approach</h2>
                  <p>
                    At Savrinda, we prioritize results and work with our clients
                    to develop strategies that deliver measurable outcomes
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-4"}>
                <GridBox>
                  <h2>Proven Track Record</h2>
                  <p>
                    years of experience serving clients across various
                    industries and sectors
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-4"}>
                <GridBox>
                  <h2> Collaborative approach</h2>
                  <p>
                    We believe that a collaborative approach leads to better
                    outcomes and long-term success for our clients.
                  </p>
                </GridBox>
              </div>
              <div className={"col-12 col-sm-4"}>
                <GridBox>
                  <h2>Innovative solutions</h2>
                  <p>
                    At Savrinda, we pride ourselves on our ability to provide
                    innovative solutions to our clients that are tailored to
                    meet their unique business needs.
                  </p>
                </GridBox>
              </div>
            </div>
          </Section>
        </div>
      </BgHeading>
    </div>
  );
}
